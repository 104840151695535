// created 26-apr-2019 by rich braun <docker@instantlinux.net>

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

import { apiUrl } from 'apicrud-ui/lib/constants';

class VersionInfo extends React.Component {
  constructor(props){
    super(props);
    this.state = { data: null }
  }
  componentDidMount(){
    fetch(apiUrl + '/health')
      .then(response => response.json())
      .then(data => this.setState({
        version: data.version,
        build_date: data.notes[0],
      }))
  }
  render() {
    const { version, releaseId, build_date } = this.state
    return(
      <div><i>Version: {version}-{releaseId && releaseId.substring(0,7)} &bull;
        Built: {build_date && build_date.split("date:")[1]}</i></div>
    )
  }
}

const aboutPage = () => (
    <Card>
	<Title title="About Conclave" />
	<CardContent>
	con·clave<br /><br />/ˈkänˌklāv/<br /><i>noun</i><br />a private meeting.<br />
	<i>synonyms:</i>	(private) meeting, gathering, assembly, conference, convention, convocation, council, session, summit, forgathering; <i>informal</i> parley, powwow, get-together<br />
"a conclave of American, European, and Japanese business leaders"<p />
	This is an events-management system built from scratch starting March 2019 by Rich Braun. Its goal is to empower event hosts and their guests to create and expand durable social connections in real-life physical locations.
	<p />
	Implementation/design includes these technologies: <a href="http://www.celeryproject.org/">celery</a>, <a href="https://aws.amazon.com/cloudfront/">CloudFront and S3</a>, <a href="https://www.docker.com/">docker</a>, <a href="http://flask.pocoo.org/">flask</a>, <a href="https://kubernetes.io/">kubernetes</a>, <a href="https://developer.mapquest.com/documentation/open/geocoding-api/">MapQuest geocoding</a>, <a href="https://www.mapbox.com/">mapbox</a>, <a href="https://mariadb.org/">MariaDB</a>, <a href="https://oauth.net/2/">OAuth 2.0</a>, <a href="](https://pyauth.github.io/pyotp/">PyOTP</a>, <a href="https://docs.python.org/3/">python 3</a>, <a href="https://www.rabbitmq.com/">RabbitMQ</a>, <a href="https://reactjs.org">react.js</a>, <a href="https://marmelab.com/react-admin">react-admin</a>, <a href="https://www.sqlalchemy.org/">sqlalchemy</a>, <a href="https://uwsgi-docs.readthedocs.io/en/latest/">uWSGI</a>.
	<p />
	Hosted on a private bare-metal Kubernetes cluster, with the exception of pictures which are hosted on AWS for faster delivery. Data is not shared externally except on an encrypted offsite backup.
	<p />
        Software copyright &copy; 2021 by Richard Braun.<p />
        <VersionInfo />
        </CardContent>
    </Card>
);
export default aboutPage;
