import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;

export const confirmRSVP = () => {
    return <Card>
      <CardContent>
      <Title title='Confirmed RSVP' />
        <p /><ActionButtons /><p />
      </CardContent>
    </Card>
};

function Headers() {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + btoa(
            sessionStorage.getItem('uid') + ':' +
                sessionStorage.getItem('token')),
        mode: 'no-cors'
    }
}

class ActionButtons extends React.Component {
  constructor(props) {
    const query = window.location.hash.split('?')[1];
    const params = new URLSearchParams(query);
    const guest_id = params.get('guest_id');
    super(props);
    this.state = {
      event_id: sessionStorage.getItem('rsvp_eid'),
      guest_id: guest_id,
      response: null,
      error: false
    }
  }
  componentDidMount() {
    const request = new Request(apiUrl + `/guest/${this.state.guest_id}`,
                                {headers: Headers(), method: 'GET'});
    fetch(request)
      .then (response => {
        if (response.status !== 200) {
          this.setState({ error: true })
        }
        return response.json();
      })
      .then (json => {
          this.setState({ response: json.response, remarks: json.remarks })
      })
  }
  render() {
    const { response, event_id, error } = this.state;
    if (error) {
      return (
        <div style={{textAlign: 'center'}}>
          A technical problem happened when confirming your RSVP<p />
          <Button component={Link} variant='contained' to='/welcome'>
            Home
          </Button>
        </div>
      )
    }
    return (
      <div style={{textAlign: 'center'}}>Thank you for your RSVP. <p />
        { response && <div>
            Your response is <div className="MuiButton-contained">
              {response}<p /></div>
            <Button component={Link} variant='contained'
              to={`/event/${event_id}/show/1`}>
                Back to Event
            </Button>
        </div>}
      </div>
    )
  }
}

export default confirmRSVP;
