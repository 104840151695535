import React from 'react';
import { AutocompleteInput, AutocompleteArrayInput, Create, Edit,
         NullableBooleanInput, NumberInput, ReferenceArrayInput,
         ReferenceField, SimpleForm, TextField,
         TextInput } from 'react-admin';

import { validateNumMax, validateSelected,
         validate255String } from 'apicrud-ui/lib/validate';

function redirect() {
    const event_id = sessionStorage.getItem('rsvp_eid');
    return event_id ? `/event/${event_id}/show/4` : '/welcome';
}

export const guestCreate = props => (
    <Create {...props} title='Invite Guests'>
	<SimpleForm redirect={redirect}>
        <ReferenceArrayInput label='People' source='people' reference='person'
            sort={{ field: 'name', order: 'ASC' }} perPage={255} >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <ReferenceArrayInput label='Lists' source='lists' reference='list'
            sort={{ field: 'name', order: 'ASC' }} >
          <AutocompleteArrayInput optionText='name' />
        </ReferenceArrayInput>
        <AutocompleteInput source='authorization' label='Action' choices={[
            { id: 'manager', name: 'host' },
            { id: 'member', name: 'confirmed' },
            { id: 'invitee', name: 'invite'},
        ]} defaultValue='invitee' />
        <TextInput disabled source='status' choices={[
            { id: 'active', name: 'active' },
            { id: 'disabled', name: 'disabled' },
        ]} defaultValue='active' />
      </SimpleForm>
    </Create>
);

export const guestEdit = ({ ...props }) => {
    var guestpartymax = sessionStorage.getItem('guestpartymax')
    return <Edit title='RSVP' {...props} undoable={false} >
	<SimpleForm redirect={`/rsvp?guest_id=${props.id}`} >
        <ReferenceField source="uid" reference="person"
                perpage={500} label="Guest" >
            <TextField source="name" />
        </ReferenceField>
        <ResponseInput />
        <NumberInput source='partysize' label='Guests in party'
            validate={validateNumMax(guestpartymax)} />
        <TextInput source='remarks' multiline validate={validate255String} />
        <TextInput disabled source='reminders' />
	{/* TODO SYS-568 workaround -- going crazy, not enough time to fix
        <ActionInput />
        <AttendedInput />
	*/}
      </SimpleForm>
    </Edit>
};

const ResponseInput = ({record, ...props}) => {
    return <AutocompleteInput source='response' choices={[
        { id: 'yes', name: 'yes' },
        { id: 'no', name: 'no' },
        { id: 'remind-me', name: 'remind me' },
    ]} allowEmpty={false}
       validate={record.rbac && record.rbac.includes('h') ?
       [] : validateSelected} />
};

const ActionInput = ({record, ...props}) => {
    return <span>{<AutocompleteInput source='authorization' label='Action'
        choices={[
            { id: 'manager', name: 'host' },
            { id: 'member', name: 'confirmed' },
            { id: 'invitee', name: 'invited'},
        ]} defaultValue='invitee' />}</span>
};

const AttendedInput = ({record, ...props}) => {
    return <span>{record.rbac && record.rbac.includes('h') &&
                  <NullableBooleanInput source='attended' />}</span>
};

export default guestCreate;
