import React from 'react';
import { AutocompleteInput, ChipField, Create, Datagrid, Edit,
	 List, ReferenceInput, SelectInput, SimpleForm,
	 TextInput } from 'react-admin';

export const themeCreate = props => (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name' />
        <ReferenceInput source='category_id' reference='category' >
           <SelectInput optionText='name' />
        </ReferenceInput>
        <TextInput disabled source='status' defaultValue='active' />
      </SimpleForm>
    </Create>
);

export const themeEdit = props => (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source='name' />
        <ReferenceInput source='category_id' reference='category' >
           <SelectInput optionText='name' />
        </ReferenceInput>
        <AutocompleteInput source='status' choices={[
            { id: 'active', name: 'active' },
            { id: 'disabled', name: 'disabled' },
        ]} />
      </SimpleForm>
    </Edit>
);

export const themeList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <ChipField source='name' />
            <ChipField source='owner' />
        </Datagrid>
    </List>
);

export default themeCreate;
